<template>
  <div class="page container p-4">
    <div>
      <h3 class="is-size-4 has-text-weight-bold mb-5 is-uppercase">
        {{ legalContent.title }}
      </h3>
      <div v-html="parsedLegalContent" />
    </div>
  </div>
</template>

<script>
import LegalContent from '@/assets/legal/cookies-policy.json'
export default {
  title: 'Política de Cookies',
  name: "CookiesPolicy",
  data() {
    return {
      legalContent: LegalContent,
      currentYear: ''+(new Date()).getFullYear()
    }
  },
  computed: {
    parsedLegalContent(){
      if(!this.legalContent){
        return ''
      }

      return this.legalContent.content.split('{currentYear}').join(this.currentYear)
    }
  }

}
</script>
